var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "space-between"
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('v-col', {
      key: item.name,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      attrs: {
        "aria-label": item.name,
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.handleClick(item);
        }
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.icon,
        "alt": ""
      }
    })], 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }