var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', {
    staticClass: "rounded-pill",
    attrs: {
      "items": _vm.items,
      "item-text": _vm.text,
      "item-value": _vm.value,
      "placeholder": "Family Site",
      "hide-details": "auto",
      "height": "40",
      "outlined": ""
    },
    on: {
      "change": _vm.handleSelectChange
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }