var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--sm"
  }, _vm._l(_vm.items, function (item) {
    return _c('v-col', {
      key: item.name,
      staticClass: "py-4",
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "grey-6--text"
    }, [_vm._v(_vm._s(item.name))]), _vm._v(" " + _vm._s(item.text) + " ")]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }