var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer-head"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_vm._v("개인정보취급방침")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-sheet', {
    attrs: {
      "color": "rgba(153,153,153,0.25)",
      "width": "1",
      "height": "12"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_vm._v("이메일 무단수집거부")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "footer-body"
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('v-img', {
    staticClass: "footer__logo",
    attrs: {
      "src": "/images/ci.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('footer-informations'), _c('p', {
    staticClass: "font-size-12 mt-16"
  }, [_vm._v("Copyright 2025. 사회복지법인 위드-캔복지재단. All Rights Reserved.")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('v-sheet', {
    attrs: {
      "max-width": "208",
      "height": "130",
      "color": "transparent"
    }
  }, [_c('v-row', {
    staticClass: "h-100",
    attrs: {
      "align": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('footer-sns')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('footer-family-sites')], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "text-lg-end",
    attrs: {
      "cols": "12",
      "lg": "2"
    }
  }, [_c('v-btn', {
    staticClass: "support-button",
    attrs: {
      "href": "https://secure.donus.org/withcan/pay/step1?_gl=1*1csn9b9*_ga*MTA5ODMyNDAzLjE3MjE4MTAwODU.*_ga_1KFQVNYJMP*MTc0Mjk2Mzc5OS4yMi4xLjE3NDI5NjU5ODYuNTkuMC4w",
      "target": "_blank",
      "width": "120",
      "height": "120",
      "icon": ""
    }
  }, [_c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "50",
      "src": "/images/icon/icon-support.svg"
    }
  }), _c('div', {
    staticClass: "mt-8 font-size-16 font-weight-bold white--text"
  }, [_vm._v("후원하기")])], 1)])], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }