<template>
    <v-select :items="items" :item-text="text" :item-value="value" placeholder="Family Site" hide-details="auto" height="40" outlined class="rounded-pill" @change="handleSelectChange" />
</template>
  
  <script>
export default {
    data() {
        return {
            items: [
                {
                    text: "대흥어린이집",
                    value: "https://cafe.naver.com/kid2",
                },
                {
                    text: "서초구립 포레스타2단지 어린이집",
                    value: "https://www.youtube.com/@%EA%B9%80%EC%A7%80%EC%97%B0-u1w",
                },
                {
                    text: "강남구8호점 자곡다함께키움센터",
                    value: "https://blog.naver.com/jagokkium",
                },
                {
                    text: "강동구6호점 아이플러스키움센터",
                    value: "https://blog.naver.com/kdiplus21",
                },
                {
                    text: "광진구9호점 우리동네키움센터",
                    value: "https://icare.seoul.go.kr/icare/user/careResve/BD_selectResveStleForm.do?q_fcltyId=GJ210601&q_tab=2",
                },
                {
                    text: "성남시다함께돌봄센터31호점(분당동어린이식당)",
                    value: "https://blog.naver.com/sndadol31",
                },
                {
                    text: "경기광주아동보호전문기관",
                    value: "http://www.gj-child.kr/",
                },
                {
                    text: "광명시아동보호전문기관",
                    value: "https://gmcchild.cafe24.com/",
                },
                {
                    text: "성남시아동보호전문기관",
                    value: "http://www.snchild.co.kr/",
                },
                {
                    text: "파주시아동보호전문기관",
                    value: "http://paju1391.kr/",
                },
                {
                    text: "강북50플러스센터",
                    value: "https://50plus.or.kr/gbc/index.do",
                },
                {
                    text: "성북50플러스센터",
                    value: "https://50plus.or.kr/sbc/index.do",
                },
                {
                    text: "강북시니어클럽",
                    value: "http://gangbuksc.or.kr/",
                },
                {
                    text: "성북시니어클럽",
                    value: "http://www.sbsc9988.or.kr/",
                },
                {
                    text: "영등포시니어클럽",
                    value: "https://ydpsc.or.kr/",
                },
                {
                    text: "양평시니어클럽",
                    value: "",
                },
                {
                    text: "고양시덕양노인종합복지관",
                    value: "https://withnoin.org/",
                },
                {
                    text: "고양시치매노인주간보호센터",
                    value: "https://gysilver.or.kr/",
                },
                {
                    text: "용인시기흥노인복지관",
                    value: "http://www.ygsenior.or.kr/",
                },
                {
                    text: "동탄어울림장애인주간보호센터",
                    value: "https://blog.naver.com/dtwith8500",
                },
                {
                    text: "강남지역자활센터",
                    value: "https://www.gangnam.go.kr/office/gjhope/main.do",
                },
                {
                    text: "화성시동탄어울림종합복지관",
                    value: "http://dongtanwith.or.kr/",
                },
            ],
        };
    },
    methods: {
        handleSelectChange(selectedValue) {
            if (selectedValue) {
                window.open(selectedValue, "_blank");
            } else {
                alert("준비 중입니다.");
            }
        },
    },
};
</script>
  