<template>
    <div v-if="className == 'sub-tab-wrap'" :class="className">
        <template v-for="item in gnbs">
            <ul class="tab tab--block" :key="item.title" v-if="item.title == sh">
                <li v-for="child in item.children" :key="child.title" class="tab__li" :class="{ active: tabActive == child.title }">
                    <a :href="child.path" class="tab__btn"
                        ><span>{{ child.title }}</span></a
                    >
                </li>
            </ul>
        </template>
    </div>
    <ul v-else-if="className == 'gnb'" :class="className">
        <template v-for="item in gnbs">
            <li v-if="!item.children" :class="className + '__li'" :key="item.title">
                <router-link :to="item.path" :class="className + '__link'"
                    ><h2>{{ item.title }}</h2></router-link
                >
            </li>

            <li v-else :class="className + '__li'" :key="item.title">
                <div v-if="isMobile" :class="className + '__link gnb__link--toggle'">
                    <h2>{{ item.title }}</h2>
                </div>
                <router-link v-else :to="item.path" :class="className + '__link gnb__link--toggle'"
                    ><h2>{{ item.title }}</h2></router-link
                >
                <ul :class="className + '__sub'">
                    <li v-for="child in item.children" :key="child.title">
                        <router-link :to="child.path">{{ child.title }}</router-link>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    props: {
        className: String,
        indexNum: String,
        sh: String,
        tabActive: String,
    },
    data: function () {
        return {
            window: {
                width: 0,
                height: 0,
            },
            gnbs: [
                {
                    title: "후원안내",
                    path: "/guide/regular",
                    children: [
                        {
                            title: "정기후원",
                            path: "/guide/regular",
                        },
                        {
                            title: "일시후원",
                            path: "/guide/temporary",
                        },
                        {
                            title: "국내아동결연",
                            path: "/guide/domestic",
                        },
                        {
                            title: "해외아동결연",
                            path: "/guide/overseas",
                        },
                        {
                            title: "기업후원",
                            path: "/guide/company",
                        },
                    ]
                },
                // {
                //     title: "법인소개",
                //     path: "/company/intro",
                //     children: [
                //         {
                //             title: "위드 캔 소개",
                //             path: "/company/intro",
                //         },
                //         {
                //             title: "나눔기업",
                //             path: "/company/partner",
                //         },
                //     ],
                // },
                {
                    title: "국내사업",
                    path: "/business-domestic/operate/care",
                    children: [
                        {
                            title: "시설운영지원",
                            path: "/business-domestic/operate/care",
                        },
                        {
                            title: "사회복지 전략기획사업",
                            path: "/business-domestic/welfare",
                        },
                        {
                            title: "지역공동체지원",
                            path: "/business-domestic/community",
                        },
                        {
                            title: "연구개발",
                            path: "/business-domestic/development",
                        },
                        {
                            title: "교육사업",
                            path: "/business-domestic/education",
                        },
                    ],
                },
                {
                    title: "해외사업",
                    path: "/business-overseas/child",
                    children: [
                        {
                            title: "해외아동결연",
                            path: "/business-overseas/child",
                        },
                        {
                            title: "지역개발",
                            path: "/business-overseas/country",
                        },
                        {
                            title: "교육지원",
                            path: "/business-overseas/education",
                        },
                        {
                            title: "긴급구호",
                            path: "/business-overseas/rescue",
                        },
                    ],
                },
                // {
                //     title : "후원하기",
                //     path : "/support/regular",
                //     children : [
                //         {
                //             title : "정기후원",
                //             path : "/support/regular",
                //         },
                //         {
                //             title : "일시후원",
                //             path : "/support/temporary",
                //         },
                //         {
                //             title : "결연후원",
                //             path : "/support/relationship",
                //         },
                //         // {
                //         //     title : "해외결제",
                //         //     path : "/support/foreign",
                //         // },
                //         // {
                //         //     title : "기업후원",
                //         //     path : "/support/corporation",
                //         // },
                //         // {
                //         //     title : "물품후원",
                //         //     path : "/support/commodity",
                //         // },
                //         {
                //             title : "유산기부",
                //             path : "/support/legacy",
                //         },
                //         {
                //             title : "후원 가이드",
                //             path : "/support/guide",
                //         },
                //     ]
                // },
                // {
                //     title: "캠페인",
                //     // path: "/campaign/campaign?code=koCampaign",
                //     path: "/campaign/campaign",
                //     children: [
                //         {
                //             title: "캠페인",
                //             // path: "/campaign/campaign?code=koCampaign",
                //             path: "/campaign/campaign",
                //         },
                //         {
                //             title: "캠페인 그 후",
                //             path: "/campaign/after",
                //         },
                //         // {
                //         //     title : "비대면 봉사활동 (단체)",
                //         //     path : "/campaign/group",
                //         // },
                //     ],
                // },
                {
                    title: "소식",
                    path: "/news/board?code=notice",
                    children: [
                        {
                            title: "공지사항",
                            path: "/news/board?code=notice",
                        },
                        {
                            title: "언론보도",
                            path: "/news/board?code=report",
                        },
                        {
                            title: "위드캔 스토리",
                            path: "/news/board?code=newsletter",
                        },
                        {
                            title: "캠페인",
                            path: "/news/campaign",
                        },
                        {
                            title: "발간 자료실",
                            path: "/news/board?code=library",
                        },
                    ]
                },
                {
                    title: "소통과 참여",
                    path: "/community/board?code=notice",
                    children: [
                        {
                            title: "공지사항",
                            path: "/community/board?code=notice",
                        },
                        {
                            title: "보도자료",
                            path: "/community/board?code=report",
                        },
                        {
                            title: "재단소식",
                            path: "/community/board?code=newsletter",
                        },
                        {
                            title: "산하시설소식",
                            path: "/community/news",
                        },
                        // {
                        //     title : "소식지",
                        //     path : "/community/board?code=news",
                        // },
                        {
                            title: "인재채용",
                            path: "/community/board?code=reference",
                        },
                        {
                            title: "자주묻는 질문",
                            path: "/community/faqs",
                        },
                        // {
                        //     title : "자원봉사",
                        //     path : "/community/board?code=volunteer",
                        // },
                        // {
                        //     title : "이벤트",
                        //     path : "/community/board?code=event",
                        // },

                        // {
                        //     title : "위드 캔 소식받기",
                        //     path : "/support/news",
                        // },
                    ],
                },
            ],
        };
    },
    computed: {
        isDesktop: function () {
            return this.window.width >= 1200;
        },
        isMobile: function () {
            return this.window.width < 1200;
        },
    },
    created: function () {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted: function () {},
    methods: {
        handleResize: function () {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
};
</script>