<template>
    <v-row justify="space-between" class="row--xs">
        <v-col v-for="item in items" :key="item.name" cols="auto">
            <v-btn :aria-label="item.name" icon @click="handleClick(item)">
                <v-img :src="item.icon" alt="" />
            </v-btn>
        </v-col>
    </v-row>
</template>
  
  <script>
export default {
    data() {
        return {
            items: [
                {
                    name: "해피빈",
                    link: "https://happylog.naver.com/hlog/kehyoung/home",
                    icon: "/images/footer/footer-happy.svg",
                },
                {
                    name: "같이가치",
                    link: "https://together.kakao.com/fundraisings/teams/5735",
                    icon: "/images/footer/footer-together.svg",
                },
                {
                    name: "페이스북",
                    icon: "/images/footer/footer-facebook.svg",
                },
                {
                    name: "인스타그램",
                    icon: "/images/footer/footer-instagram.svg",
                },
                {
                    name: "유튜브",
                    icon: "/images/footer/footer-youtube.svg",
                },
            ],
        };
    },
    methods: {
        handleClick(item) {
            if (item.link) {
                window.open(item.link, "_blank");
            } else {
                alert("준비 중입니다.");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.v-btn {
    width: 32px !important;
    height: 32px !important;
}    
</style>