<template>
    <footer class="footer">
        <div class="footer-head">
            <v-container>
                <v-row align="center">
                    <v-col cols="auto">
                        <router-link to="/privacy-policy">개인정보취급방침</router-link>
                    </v-col>
                    <v-col cols="auto">
                        <v-sheet color="rgba(153,153,153,0.25)" width="1" height="12" />
                    </v-col>
                    <v-col cols="auto">
                        <router-link to="/privacy-policy">이메일 무단수집거부</router-link>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="footer-body">
            <v-container>
                <v-row>
                    <v-col cols="12" lg="3">
                        <v-img src="/images/ci.svg" class="footer__logo" />
                    </v-col>
                    <v-col cols="12" lg="4">
                        <footer-informations />
                        <p class="font-size-12 mt-16">Copyright 2025. 사회복지법인 위드-캔복지재단. All Rights Reserved.</p>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-sheet max-width="208" height="130" color="transparent">
                            <v-row align="space-between" class="h-100">
                                <v-col cols="12">
                                    <footer-sns />
                                </v-col>
                                <v-col cols="12">
                                    <footer-family-sites />
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" lg="2" class="text-lg-end">
                        <v-btn href="https://secure.donus.org/withcan/pay/step1?_gl=1*1csn9b9*_ga*MTA5ODMyNDAzLjE3MjE4MTAwODU.*_ga_1KFQVNYJMP*MTc0Mjk2Mzc5OS4yMi4xLjE3NDI5NjU5ODYuNTkuMC4w" target="_blank" width="120" height="120" icon class="support-button">
                            <div>
                                <v-img max-width="50" src="/images/icon/icon-support.svg" class="mx-auto" />
                                <div class="mt-8 font-size-16 font-weight-bold white--text">후원하기</div>
                            </div>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </footer>
</template>

<script>
import FooterFamilySites from "./main-footer/footer-family-sites.vue";
import FooterInformations from "./main-footer/footer-informations.vue";
import FooterSns from "./main-footer/footer-sns.vue";

export default {
    components: {
        FooterInformations,
        FooterSns,
        FooterFamilySites,
    },
};
</script>

<style scoped>
.footer {
    font-size: 1.4rem;
    color: #999;
}
.footer-head {
    background-color: var(--v-primary-base);
    color: #fff;
    padding: 12px 0;
}
.footer-body {
    padding: 20px 0;
}
.footer__logo {
    width: 200px;
}
.support-button {
    background-color: var(--v-primary-base) !important;
}
@media (min-width: 1024px) {
    .footer__logo {
        width: 210px;
    }
    .footer-body {
        padding: 40px 0;
    }
}
@media (min-width: 1200px) {
}
</style>
