<template>
    <v-row class="row--sm">
        <v-col v-for="item in items" :key="item.name" cols="auto" class="py-4">
            <span class="grey-6--text">{{ item.name }}</span> {{ item.text }}
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    name: "대표이사",
                    text: "전선영",
                },
                {
                    name: "사업자등록번호",
                    text: "112-82-03363",
                },
                {
                    name: "TEL",
                    text: "02-2057-2296",
                },
                {
                    name: "팩스",
                    text: "02-2057-2295",
                },
                {
                    name: "주소",
                    text: "서울특별시 성북구 화랑로11길 26 갑을명가 2층 215",
                },
            ],
        };
    },
};
</script>